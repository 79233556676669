import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import RatingManuel from "../../../comment/rating";
import SocialMedias from "../components/socialMedias";
import apiBusiness from "../../../../utils/apiBusiness";
import CommentsRating from "../../../comment/commentsRating";
import SlideshowGalery from "../components/slideshowGalery/slideshowGalery";
import { CartContext } from "../../../contexts/cartContext";
import { isValidPhoneNumber } from "react-phone-number-input";
import GoogleMap from "../../../googleMap/googleMap";
import renderData from "../../../../utils/renderData";
import ShareSocialMedias from "../components/shareSocialMedias";
import ToastNote from "../../../golbal/notifications/toastNote";
import { CircularProgress } from "@mui/material";
// import Countdown from "../../../golbal/countDown/countDown";
import BusCardSlider from "../../../golbal/sliders/busCardSlider";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import QRCode from "qrcode.react";
import MetaDecorator from "../../../../utils/metaDecorator";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import ActionBtnGenerator from "../../demy/components/actionBtnGenerator";
import BusPageSch from "../components/busPageSch";
import { FiberNew } from "@mui/icons-material";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";

export default function BusinessPage(props) {
  const [passwordCounter, setPaswordCounter] = useState(1);
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [codeA, setCodeA] = useState();
  const [emailCode, setEmailCode] = useState();
  const { user, signedUser } = useContext(CartContext);

  const [contactPhone, setContactPhone] = useState();
  const [request, setRequest] = useState({ email: "", message: "" });

  const data = useLocation().state;
  const {
    lang,
    state: { state, stateShort, lat, lng },
    country,
    countryShort,
  } = tools.getItemFromLocalStorage("initSetup", {});

  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};

  const { id } = useParams();

  const [business, setBusiness] = useState(
    props?.data?.preview ? props?.data : data?.business
  );
  const [busSlider, setBusSlider] = useState([]);
  // console.log("business", business);
  const [loading, setLoading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    save: false,
  });

  const repliesCounter = () => {
    const comments = business?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    if (!business?._id)
      return messageRef.current.showError({
        message: "این کسب و کار تاکنون ثبت نشده است.",
      });
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    // console.log("saveHandler", business);
    const response = await api.saveHandler({
      id: business._id,
      token: user.token,
      section: "business",
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 10);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // user.saved.business.find((x) => x._id == business?._id);
    !user?.saved?.business?.includes(business?._id)
      ? messageRef.current.showSuccess({
          message: "کسب و کار با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "کسب و کار از لیست ذخیره حذف شد.",
        });

    signedUser(response.data);
  };

  useEffect(() => {
    if (!id || props?.data?.preview) return;
    (async () => {
      // if (id?.length !== 24)
      //   return messageRef.current.showError({
      //     message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
      //   });
      const response = await apiBusiness.businessDetails(id);
      if (response.error) return alert(response.error);
      setBusiness(response.data);
      if (effectRan.current) return;
      fetchBusSlider(response.data);
      effectRan.current = true;
    })();

    if (user?.roles?.includes(1000)) return;
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", handleContextMenu);
    const handleKeydown = (e) => {
      if (
        e.ctrlKey &&
        (e.key === "c" || e.key === "x" || e.key === "C" || e.key === "X")
      ) {
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [id]);

  async function fetchBusSlider(data) {
    setTimeout(async () => {
      // console.log("fetchBusSlider", data);
      const filters = {
        lang: window.lang,
        country: data.moreInfo.address.country,
        state: data.moreInfo.address.state,
        stateShort: data.moreInfo.address.stateShort,
        mainCatId: data.category.mainCat._id,
        subCatId: data.category.subCat._id,
        pageNumber: 1,
        itemsToShow: 12,
        sort: "dateB",
      };
      console.log(filters);
      setLoading((pre) => ({ ...pre, busSlider: true }));
      const responseSlider = await apiBusiness.BusCardsSlider({ filters });
      setLoading((pre) => ({ ...pre, busSlider: false }));
      // console.log(responseSlider);
      if (responseSlider.error) return;
      setBusSlider(responseSlider.data);
    }, 2000);
  }

  // console.log(business);

  function claimHandle(e) {
    e.preventDefault();
    console.log(e.target);
    if (user.userName)
      return navigate(
        `/${window.lang}/dashboard/user/business/edit/${tools.slugMaker({
          title: business.busName[window.lang],
          city: business?.moreInfo?.address?.city,
          state: business?.moreInfo?.address?.state,
          serial: business?.serial,
        })}`,
        { state: business },
        { replace: true }
      );
  }
  const openGoogleMaps = (destination) => {
    if (!destination || !business?.moreInfo?.address?.displayAddress)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const GoogleMapsIframe = ({ destination }) => {
    const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
      destination
    )}`;

    return (
      <div>
        <iframe
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          src={embedUrl}
          allowFullScreen=""
        ></iframe>
      </div>
    );
  };
  const url = `https://www.metamarce.com/${
    window.lang
  }/business/page/${tools.slugMaker({
    title: business?.busName[window.lang],
    city: business?.moreInfo?.address?.city,
    state: business?.moreInfo?.address?.state,
    serial: business?.serial,
  })}`;

  const title = `${business?.busName?.[window.lang]} | ${
    business?.category?.subCat?.[window.lang]
  } در ${window.capitalize(business?.moreInfo?.address?.city)}`;
  const description = `${business?.moreInfo?.desc?.[window.lang]?.substring(
    0,
    120
  )} | ${
    business?.moreInfo?.address.municipality +
    " - " +
    business?.moreInfo?.address.city
  }`;
  const structuredData = [
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: business?.busName?.[window.lang],
      description: business?.moreInfo?.desc?.[window.lang],
      priceRange: "$$",
      telephone: business?.moreInfo?.businessPhone,
      email: business?.moreInfo?.email,

      // image: [
      //   business?.moreInfo?.photos?.businessCard,
      //   business?.moreInfo?.photos?.coverPhoto,
      //   business?.moreInfo?.photos?.logo,
      //   ...(business?.moreInfo?.photos?.bodyPhotos ?? []),
      // ],
      image: {
        "@type": "ImageObject",
        url:
          business?.moreInfo?.photos?.businessCard ||
          business?.moreInfo?.photos?.coverPhoto,
        contentUrl:
          business?.moreInfo?.photos?.businessCard ||
          business?.moreInfo?.photos?.coverPhoto,
        height: 800,
        width: 600,
      },
      url: url,
      address: {
        "@type": "PostalAddress",
        addressLocality: business?.moreInfo?.address?.city?.toUpperCase() ?? "",
        addressRegion: business?.moreInfo?.address?.state?.toUpperCase(),
        addressCountry:
          business?.moreInfo?.address?.countryShort?.toUpperCase(),
        postalCode: business?.moreInfo?.address?.postalCode ?? "",
        streetAddress: `${business?.moreInfo?.address?.streetNumber} ${business?.moreInfo?.address?.route}`,
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: business?.moreInfo?.address?.coordinates?.lat || "",
        longitude: business?.moreInfo?.address?.coordinates?.lng || "",
      },

      ...(business?.feedbackInfo?.rates > 0 && {
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: business?.feedbackInfo?.rate ?? 0,
          bestRating: 5,
          ratingCount: business?.feedbackInfo?.rates ?? 0,
        },
      }),
      ...(business?.feedbackInfo?.comment?.comments?.length > 0 && {
        review: business?.feedbackInfo?.comment?.comments?.map((rev) => ({
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: rev?.rate || 0,
          },
          author: {
            "@type": "Person",
            name: rev?.userName,
          },
        })),
      }),
      ...(business?.moreInfo?.tags?.length > 0 && {
        keywords: business?.moreInfo?.tags,
      }),
      openingHoursSpecification: business?.moreInfo?.workingDays?.map(
        (day) => ({
          "@type": "OpeningHoursSpecification",
          dayOfWeek: day.weekDay,
          opens: day.from,
          closes: day.to,
        })
      ),
      additionalType: [
        business?.category?.subCat?.[window.lang],
        // Add more categories as needed
      ],
      // "acceptsReservations": "True"
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: business?.busName?.[window.lang],
      description: business?.moreInfo?.desc?.[window.lang],
      url: url,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/business`,
              name: `کسب و کارها`,
              description: `نیازمندیها و کسب و کارهای ایرانی `,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": url,
              name: business?.busName?.[window.lang],
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title,
    description,
    section: "local business page",
    url: url,
    // canonical: `https://www.metamarce.com/${window.lang}/business/page/${tools.slugMaker({title:business?.busName[window.lang],city:business?.moreInfo?.address?.city,state:business?.moreInfo?.address?.state,serial:business?.serial})}`,
    img: business?.moreInfo?.photos?.businessCard,
    type: "business.business",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* <div className="bus-bio-bg"> */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}`} className="breadcrumb-item">
              خانه
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${window.lang}/business`} className="breadcrumb-item">
              کسب و کارها
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/${window.lang}/business/filters`}
              className="breadcrumb-item"
            >
              لیست بیزنس ها
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong>
                {" "}
                {business?.busName?.[window.lang]?.substring(0, 20)}
              </strong>
            </span>
          </li>
        </ol>
      </nav>
      {/* </div>         */}

      {/* ****** cover ******** */}
      <div
        className="listing-hero pt-0"
        data-setbg="/img/img/listing/details/listing-hero.jpg"
        style={{
          objectFit: "cover",
          backgroundImage: `url(${business?.moreInfo?.photos?.coverPhoto})`,
        }}
      >
        <div className=" container2 px-md-5 px-3 pt-0">
          <div className="row px-1 px-lg-5 ">
            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
              <div className="listing-cover-page">
                <div className="container">
                  <div className="picture-container">
                    <div className="picture">
                      <img
                        // src="/img/Images/icons8-add-image-64.png"
                        src={business?.moreInfo?.photos.logo}
                        className="picture-src"
                        id="wizardPicturePreview"
                        title={business?.busName?.[window.lang]?.substring(
                          0,
                          20
                        )}
                        loading="lazy"
                      />
                      {/* <input type="file" id="wizard-picture" className="" /> */}
                    </div>
                  </div>
                  <div className="bus-bio-bg">
                    <h1 className="business-name-cover">
                      {tools.linesSpliter(business?.busName?.[window.lang])} 
                      <span>
              {             
              business?.moreInfo?.status === "01-draft" ? (
                <Tooltip1 message="این کسب و کار توسط کاربرها پیشنهاد و ثبت شده است." />
              ) : // <i className="bi bi-patch-question-fill"></i>
              business?.moreInfo?.status === "03-verified" ? (
                <Tooltip1
                  icon={<MyIcons icon={business?.moreInfo?.status} />}
                  message=" هویت مالک کسب و کار تائید شده است."
                />
              ) : 
                business?.moreInfo?.status === "02-claimed" ? (
                  <Tooltip1 message="این کسب و کار توسط مالک آن ثبت و به روزسانی می شود." icon={<span className='small-gray-font bg-greenGray px-1 text-beigeYellow rounded'>Claimed</span>}/>
              ):
              business?.moreInfo?.status === "04-trusted" ? (
                <Tooltip1
                  icon={<MyIcons icon={business?.moreInfo?.status} />}
                  message=" هویت کسب و کار و مالک آن تائید شده است."
                />
              ) : (
                ""
              )}
            </span>
                    </h1>
                    {/* <!-- *******rating stars shold show up here -->*/}
                    <p className="text-start">
                      <a href="#comments2">
                        <RatingManuel value={business?.feedbackInfo?.rate} />
                        <span>
                          {Math.floor(business?.feedbackInfo?.rate * 100) / 100}
                        </span>
                        <span className="review-text">
                          (
                          {business?.feedbackInfo?.rates
                            ? business?.feedbackInfo?.rates
                            : "بدون "}
                          رای)
                        </span>
                      </a>
                      {/* <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {business?.feedbackInfo?.views} )
                      </span> */}
                      <span className="review-text text-center" dir="rtl">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {tools.dynamicNumber({
                          number: business?.feedbackInfo?.views,
                          lang: window.lang,
                        })}
                        )
                      </span>
                    </p>
                    <div className="d-flex mt-5 mb-1">
                      {/* keep This  */}
                      {/* <div title=" تاریخ ثبت " className="m-0">
                        <i className="bi bi-calendar-plus Business-cover-icon px-2 "></i>
                        <span className="small-gray-font">
                        
                          {tools.dynamicDate({
                            date: business?.createdAt,
                            countryShort,
                            lang: window.lang,
                            showYear: true,                            
                          })}
                        </span>
                      </div> */}
                      <div title="آخرین به روز رسانی " className="m-0">
                        <i className="bi bi-calendar2-check Business-cover-icon px-2 "></i>
                        <span className="small-gray-font">
                          {tools.dynamicDate({
                            date: business?.moreInfo?.lastUpdate,
                            countryShort,
                            lang: window.lang,
                            showYear: true,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-5 px-0 d-flex flex-wrap align-items-end justify-content-lg-end justify-content-center ">
              {/* <!-- Button trigger modal --> */}

              {business?.moreInfo?.status === "01-draft" && (
                <div>
                  <button
                    type="button"
                    className="btn btn-primary share-cover-btn m-2"
                    // data-bs-toggle="modal"
                    // data-bs-target={
                    //   user.roles?.includes(9999)
                    //     ? "#claimModal"
                    //     : "#exampleModalToggle"
                    // }
                    onClick={(e) => {
                      if (!window.logined(9999, "busClaim")) return;
                      const myModal = document.getElementById("claimModal"); // Get the modal element
                      const modal = new window.bootstrap.Modal(myModal, {
                        backdrop: "static",
                        keyboard: true,
                      });
                      modal.show();
                    }}
                  >
                    ادعای مالکیت
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="claimModal"
                    tabIndex="-1"
                    aria-labelledby="claimModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="claimModalLabel">
                            آیا این صفحه کسب و کار شماست؟
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-7">
                              <p>
                                برای ادعای مالکیت، نیاز به تائید شماره تلفن
                                همراه می باشد.
                              </p>
                              {business?.email && (
                                <div className="claim-box">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div
                                      className="form-label mb-0 mx-3 cursor fw-bold"
                                      htmlFor="showEmailBtn"
                                      onClick={(e) => {
                                        console.log(
                                          document.querySelector(
                                            ".hideShowControl"
                                          ).style.display === "none"
                                        );
                                        const elEmail =
                                          document.querySelector(
                                            ".hideShowControl"
                                          );
                                        elEmail.style.display =
                                          elEmail.style.display === "block"
                                            ? "none"
                                            : "block";

                                        const elPhone = document.querySelector(
                                          ".hideShowControlTextmessage"
                                        );
                                        elPhone.style.display = "none";
                                      }}
                                    >
                                      دریافت ایمیل
                                    </div>
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    className="hideShowControl  "
                                  >
                                    <div className="pt-3">
                                      <div className=" m-0">
                                        فرستادن ایمیل به :
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span> {business?.email}</span>
                                        <span>
                                          <button
                                            type="button"
                                            className="btn btn-primary button-filter button-filter-tag text-center"
                                            onClick={sendEmailCode}
                                          >
                                            بفرست
                                            {loading.loading1 && (
                                              <CircularProgress
                                                color="inherit"
                                                size="20px"
                                              />
                                            )}
                                          </button>
                                        </span>
                                      </div>
                                      {/* <Countdown style={{ display: "none" }} /> */}
                                    </div>
                                  </div>

                                  <div
                                    style={{ display: "none" }}
                                    id="getEmailCodeInput"
                                  >
                                    <div className="claim-box2 d-flex flex-column align-items-center text-center p-0 pt-2 ">
                                      <div>
                                        کد دریافتی در ایمیل را اینجا وارد کنید:
                                      </div>
                                      <div className="d-flex justify-content-center my-0">
                                        <input
                                          type="text"
                                          autoComplete="off"
                                          className="form-control box3 w-50 text-center  p-0"
                                          onChange={(e) =>
                                            setEmailCode(e.target.value)
                                          }
                                        />
                                        <div className="d-flex justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary button-filter button-filter-tag m-3 d-flex align-items-center  "
                                          >
                                            <div
                                              className="d-flex align-items-center text-center"
                                              onClick={
                                                checkEmailCodeAndShowPhoneInput
                                              }
                                            >
                                              <span className="px-1">
                                                ادامه
                                              </span>
                                              {loading.loading2 && (
                                                <CircularProgress
                                                  color="inherit"
                                                  size="20px"
                                                />
                                              )}
                                              <i className="bi bi-caret-left ms-1 "></i>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center my-2">
                                    <div
                                      id="getPhoneNumberInput"
                                      style={{ display: "none" }}
                                    >
                                      <div>
                                        شماره تلفن خود را اینجا وارد کنید:
                                      </div>
                                      <div className="d-flex justify-content-center my-0">
                                        <input
                                          ltr
                                          type="tel"
                                          className="form-control box3 w-50 text-center p-0 "
                                          onChange={(e) =>
                                            setContactPhone(e.target.value)
                                          }
                                          placeholder="+1 514 4444444"
                                        />

                                        <div className="d-flex justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary button-filter button-filter-tag m-3 d-flex align-items-center "
                                            disabled={
                                              !isValidPhoneNumber(
                                                contactPhone || "+1"
                                              )
                                            }
                                          >
                                            <div
                                              className="d-flex align-items-center px-1"
                                              onClick={sendCodeToContactPhone}
                                            >
                                              ادامه
                                              {loading.loading2 && (
                                                <CircularProgress
                                                  color="inherit"
                                                  size="20px"
                                                />
                                              )}
                                              <i className="bi bi-caret-left ms-1"></i>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                      {contactPhone?.length > 0 &&
                                        !isValidPhoneNumber(contactPhone) && (
                                          <div
                                            style={{ color: "red" }}
                                            className="showError"
                                          >
                                            شماره را با فرمت درست وارد کنید.
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="claim-box">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div
                                    className="form-label mb-0 mx-3 btn-cart cursor fw-bold"
                                    htmlFor="showTextmessageBtn"
                                    onClick={(e) => {
                                      console.log(
                                        document.querySelector(
                                          ".hideShowControlTextmessage"
                                        ).style.display === "none"
                                      );
                                      const elPhone = document.querySelector(
                                        ".hideShowControlTextmessage"
                                      );
                                      elPhone.style.display =
                                        elPhone.style.display === "block"
                                          ? "none"
                                          : "block";
                                      const elEmail =
                                        document.querySelector(
                                          ".hideShowControl"
                                        );
                                      elEmail.style.display = "none";
                                    }}
                                  >
                                    دریافت پیامک
                                  </div>
                                </div>
                                <div
                                  style={{ display: "none" }}
                                  className="hideShowControlTextmessage  "
                                >
                                  <div className="pt-3">
                                    <p className=" m-0">
                                      <div>فرستادن پیامک به :</div>
                                      <div className="d-flex align-items-center ">
                                        <span dir="ltr">
                                          {tools.formatPhoneNumber(
                                            business?.moreInfo?.suggestMode
                                              ? business?.moreInfo
                                                  ?.businessPhone
                                              : business?.moreInfo?.contactPhone
                                          )}
                                        </span>
                                        <span>
                                          <button
                                            type="button"
                                            className="btn btn-primary button-filter button-filter-tag "
                                            onClick={sendCodeToContactPhone}
                                          >
                                            بفرست
                                          </button>
                                        </span>
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{ display: "none" }}
                                id="getPhoneCodeInput"
                              >
                                <div className=" d-flex flex-column align-items-center text-center ">
                                  <div>کد پیامکی رو این جا وارد کنید:</div>
                                  <input
                                    type="text"
                                    className="form-control box3 w-50 text-center "
                                    onChange={(e) => setCodeA(e.target.value)}
                                  />
                                  <div className="d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary button-filter button-filter-tag m-3 "
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={preActivationHandle}
                                      >
                                        ادامه و تکمیل ثبت نام
                                        {loading.loading2 && (
                                          <CircularProgress
                                            color="inherit"
                                            size="20px"
                                          />
                                        )}
                                        <i className="bi bi-caret-left ms-1"></i>
                                      </div>
                                    </button>
                                  </div>

                                  <div className="megamenu">
                                    <a
                                      href="#"
                                      onClick={sendCodeToContactPhone}
                                    >
                                      درخواست کد جدید
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5 align-items-center my-5 display-none-md">
                              <img
                                src="/img/illustration/task-animate.svg"
                                alt="claim"
                                loading="lazy"
                                // style={{ verticalAlign: "center" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {/* <a
                            href={`/${window.lang}/prompt/claimBen`}
                            target="_blank"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                          >
                            چرا باید ادعا کنم؟
                          </a> */}
                          <button
                            type="button"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                            data-bs-dismiss="modal"
                          >
                            خیر و بستن صفحه
                          </button>
                          {/* <button
                            type="button"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                          >
                            ادعای مالکیت و تکمیل ثبت نام
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {user?.roles?.includes(1000) && (
             
                  <span
                    className="btn btn-primary share-cover-btn m-2"
                    onClick={e=>deleteHandle(business,user,messageRef,setLoading)}
                  >
                    پاک کردن ‌<i className="bi bi-arrow-repeat"></i>
                  </span>)}

              {((business?.moreInfo?.history?.claimedBy[0]?._id === user?._id &&
                (user?._id ?? false)) ||
                (!business?.moreInfo?.history?.claimedBy[0]?._id &&
                  business?.moreInfo?.history?.createdBy?._id ===
                    (user?._id ?? false)) ||
                user?.roles?.includes(1000)) && (
                <>
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    // data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      // user.roles?.includes(9999)?
                      `/${
                        window.lang
                      }/dashboard/user/business/edit/${tools.slugMaker({
                        title: business?.busName[window.lang],
                        city: business?.moreInfo?.address?.city,
                        state: business?.moreInfo?.address?.state,
                        serial: business?.serial,
                      })}`
                      // : "#exampleModalToggle"
                    }
                    state={business}
                    type="Link"
                  >
                    ویرایش ‌<i className="bi bi-arrow-repeat"></i>
                  </Link>
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    // data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      // user.roles?.includes(9999)?
                      `/${window.lang}/dashboard/user/business/addEmployes/${business?._id}`
                      // : "#exampleModalToggle"
                    }
                    state={business}
                    type="Link"
                  >
                    تنظیم برنامه کاری کارمندان ‌
                    <FiberNew />
                    {/* <i className="bi bi-arrow-repeat"></i> */}
                  </Link>
                </>
              )}

              {business?.moreInfo?.history?.claimedBy[0]?._id ===
                (user?._id ?? false) &&
                business.moreInfo.status !== "01-draft" && (
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      user.roles?.includes(9999) &&
                      `/${window.lang}/dashboard/user/ads/plans?_id=${business?._id}&targetIdentifier=Business`
                    }
                    state={business}
                    type="Link"
                  >
                    بیشتر دیده شوید‌<i className="bi bi-magic"></i>
                  </Link>
                )}

              <button
                className="btn btn-primary share-cover-btn m-2 "
                data-bs-toggle="modal"
                // data-bs-target={`#myModel${business?._id}`}
                data-bs-target={`#myModel${business?._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                همرسانی
                <i className="bi bi-share-fill mx-2"></i>
              </button>

              {/* <!-- Modal --> */}
              <ShareSocialMedias
                url={`${window.location.origin}/${
                  window.lang
                }/business/page/${tools.slugMaker({
                  title: business?.busName[window.lang],
                  city: business?.moreInfo?.address?.city,
                  state: business?.moreInfo?.address?.state,
                  serial: business?.serial,
                })}`}
                title={business?.busName?.[window.lang]}
                img={business?.moreInfo?.photos?.businessCard}
                _id={business?._id}
              />

              <button
                className="btn btn-outline-secondary save-cover-btn m-2"
                onClick={saveHandler}
              >
                ذخیره
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.business?.includes(business?._id)
                        ? "bi bi-bookmark-fill ms-1"
                        : "bi bi-bookmark ms-1"
                    }
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-5">
        <div className="row p-0 m-0">
          <div className=" col-md-6 d-flex flex-wrap  justify-content-center mt-3 ">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/business/filters?mainCat=${
                business?.category?.mainCat?.[window.lang]
              }&mainCatId=${business?.category?.mainCat._id}`}
              className="btn btn-primary button-filter button-filter-tag"
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {business?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lang}/business/filters?subCat=${
                business?.category?.subCat?.[window.lang]
              }&subCatId=${business?.category?.subCat._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {business?.category?.subCat?.[window.lang]}
            </Link>
          </div>

          <div className=" col-md-6 d-flex flex-wrap  justify-content-center  mt-3">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              {repliesCounter()} دیدگاه‌
            </a>
            <Link
              className="btn btn-primary button-filter "
              to={`/${window.lang}/jobs/filters?busId=${
                business?._id
              }&busName=${business?.busName?.[window.lang]}`}
            >
              <span>
                <i className="bi bi-person-square me-1"></i>
              </span>
              همکاری
            </Link>

            {/* KEEEEEEEEEEP */}
            {/* <a href="#" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-shop me-1"></i>
              </span>
              فروشگاه
            </a> */}
            {business?.scheduleInfo?.length > 0 && (
              <a
                href="#tutor-schedule"
                className="btn btn-primary button-filter "
              >
                <span>
                  {/* <i className="bi bi-calendar-check me-1"></i> */}
                  <FiberNew />
                </span>
                قرار ملاقات
              </a>
            )}
            <button
              onClick={(e) => {
                if (!window.logined(9999)) return;
                const myModal = document.getElementById("messageModal"); // Get the modal element
                const modal = new window.bootstrap.Modal(myModal, {
                  // backdrop: "static",
                  keyboard: true,
                });
                modal.show();
              }}
              className="btn btn-primary button-filter "
            >
              <span>
                <i className="bi bi-send-plus me-1"></i>
              </span>
              فرستادن پیام
            </button>
            {/* <!-- Modal --> */}
            <div
              className="modal fade"
              id="messageModal"
              tabIndex="-1"
              aria-labelledby="sendMessageModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title titr5  "
                      id="sendMessageModalLabel"
                    >
                      فرستادن پیام به {business?.busName?.[window.lang]}
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-7">
                        <div>
                          {/* <label
                            className="med-gray-font m-3"
                            htmlFor="sessionRequest"
                          >
                             اگه پرسشی از {business?.busName[window.lang]} دارید میتونید مستقیما ازشون بیرسید.
                          </label> */}
                          <textarea
                            placeholder="پیامتون رو این‌جا بنویسید..."
                            className="w-100 px-3 border-0"
                            name=""
                            id="sessionRequest"
                            cols="50"
                            rows="10"
                            onChange={(e) => {
                              setRequest((pre) => ({
                                ...pre,
                                message: e.target.value,
                              }));
                              e.target.style.height = "auto";
                              e.target.style.height =
                                e.target.scrollHeight + "px";
                            }}
                          ></textarea>
                          <button
                            type="button"
                            className="btn btn-primary border-0  save-cover-btn w-100 px-3"
                            // data-bs-dismiss="modal"
                            onClick={sendTextHandle}
                          >
                            فرستادن پیام
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5 align-items-center  display-none-md">
                        <img
                          src="/img/Bussiness-page/Messaging-bus.svg"
                          alt="claim"
                          loading="lazy"
                          // style={{ verticalAlign: "center" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}

        {/* <!-- <div className="container"> -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-7 order-md-last">
            {business?.moreInfo?.photos?.bodyPhotos?.length > 0 && (
              <div className="listing-details-information py-3 mb-5">
                <div className=" divider-small border-0 mt-4 px-5">
                  <span>
                    <img
                      src="/img/bussiness-page/gallery.png"
                      className="arrow2 pe-2"
                      alt="info"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">گالری </span>

                  {/* <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"/>  */}
                </div>
                <div className="px-3">
                  <SlideshowGalery
                    data={business?.moreInfo?.photos.bodyPhotos}
                    alt={business?.busName?.[window.lang]}
                  />
                </div>
              </div>
            )}
            <div className="listing-details-information p-5  pt-3 my-5">
              <div className=" divider-small border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/information.png"
                    className="arrow2 pe-2"
                    alt="info"
                    loading="lazy"
                  />
                </span>

                <span className="p-0 mb-0 ">توضیحات </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div>
                {tools.linesSpliter(business?.moreInfo?.desc?.[window.lang])}
              </div>
            </div>
            {business?.moreInfo?.video?.length > 0 && (
              <div className="listing-details-information py-3  my-5 ">
                <div className=" divider-small border-0 mt-4 px-5">
                  <span>
                    <img
                      src="/img/bussiness-page/media-player.png"
                      className="arrow2 pe-2"
                      alt="video"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">ویدیو </span>
                </div>
                {business?.moreInfo?.isPaid?.video && (
                  <div className="px-3">
                    <div className="ratio ratio-16x9">
                      {generateYoutubeIframe(
                        business?.moreInfo?.video[0]?.youtube ?? ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {business?.moreInfo?.isPaid?.facilities && (
              <div className="listing-details-information p-3  my-5  ">
                <div className=" divider-small border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/notes.png"
                      className="arrow2 pe-2"
                      alt="facilities"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-3 ">امکانات </span>
                </div>
                <div className="row row-cols-auto  justify-content-start g-5">
                  {renderData.facilities.map((facil, i) =>
                    !business?.moreInfo?.facilities?.includes(facil.name) ? (
                      ""
                    ) : (
                      <div key={i} className="text-center">
                        <img
                          src={facil.img}
                          className=" img-facilities"
                          alt={facil?.[window.lang]}
                          loading="lazy"
                        />

                        <div className="form-check p-0 my-1">
                          {/* <input className="form-check-input" type="checkbox" checked /> */}
                          <div className="form-check-label">
                            {facil?.[window.lang]}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            {business?.scheduleInfo?.length > 0 && (
              <BusPageSch
                business={business}
                user={user}
                messageRef={messageRef}
              />
            )}
          </div>
          <div className=" col-lg-5 ">
            {/* <!-- <div className=""> -->*/}

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">اطلاعات تماس</span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>

              {/* <div style={{direction:"ltr"}}> */}
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span>
                    {business?.moreInfo?.address?.displayAddress && (
                      <span className="addressBusPage">
                        {`
                    ${
                      business?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(
                            business?.moreInfo?.address?.number
                          ) +
                          ","
                        : ""
                    }
                    ${
                      business?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            business?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      business?.moreInfo?.address?.route?.length
                        ? window.capitalize(
                            business?.moreInfo?.address?.route
                          ) + ","
                        : ""
                    }
                    
                    ${
                      business?.moreInfo?.address?.postalCode
                        ? business?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                      </span>
                    )}
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      business?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            business?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      business?.moreInfo?.address?.city?.length
                        ? window.capitalize(business?.moreInfo?.address?.city) +
                          ","
                        : ""
                    }
                    ${
                      business?.moreInfo?.address?.state?.length
                        ? window.capitalize(
                            business?.moreInfo?.address?.state
                          ) + ","
                        : ""
                    }
                    ${
                      business?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            business?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>

                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  <a href={`tel:${business?.moreInfo?.businessPhone}`}>
                    {tools.formatPhoneNumber(business?.moreInfo?.businessPhone)}
                  </a>
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <div className="text-break">
                    {business?.moreInfo?.website?.length > 0 ? (
                      <a
                        href={
                          (!business?.moreInfo?.website.startsWith("http")
                            ? "http://"
                            : "") + business?.moreInfo?.website
                        }
                        target="_blank"
                      >
                        {business?.moreInfo?.website}
                      </a>
                    ) : (
                      <span title="وبسایت تاکنون ثبت نشده است">
                        بدون وبسایت
                      </span>
                    )}
                  </div>
                </li>
                <li className="group-list-item ">
                  <i className="bi bi-envelope mx-3"></i>
                  <div className="text-break">
                    <a href={`mailto:${business?.email}`}>{business?.email}</a>
                  </div>
                </li>
                {business?.moreInfo?.address?.displayAddress && (
                  <li
                    className="group-list-item pe-auto cursor"
                    onClick={() =>
                      openGoogleMaps(business?.moreInfo?.address?.googleAddress)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="32"
                      fill="currentColor"
                      className="bi bi-sign-turn-right mx-3"
                      viewBox="0 0 16 32"
                    >
                      <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                      <path
                        fillRule="evenodd"
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                      />
                    </svg>
                    <div className="map-dir">مسیریابی با نقشه گوگل</div>
                  </li>
                )}
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={business?.moreInfo?.socialMedia} />

              {business?.moreInfo?.address?.displayAddress && (
                <div className="listing-details-map mt-3">
                  <GoogleMap data={business?.moreInfo?.address.coordinates} />
                </div>
              )}
            </div>
            {business?.moreInfo?.serviceTypes?.length +
              business?.moreInfo?.languages?.length +
              business?.moreInfo?.paymentMethods?.length >
              0 && (
              <div className="listing-sidebar-section mt-0 mb-5">
                <div className="divider2 divider-small">
                  <span>
                    <img
                      src="/img/bussiness-page/information-2.png"
                      className="arrow2 pe-2 py-1"
                      alt="more info"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-0 ">اطلاعات تکمیلی</span>
                </div>
                <div
                  className="accordion w-100"
                  id="accordionPanelsStayOpenExample"
                >
                  {business?.moreInfo?.languages?.length > 0 && (
                    <div className="accordion-item2">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button accordion-button--bus-moreinfo collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          زبان خدمات
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse  "
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body ">
                          {business?.moreInfo?.languages?.map((lang, i) => (
                            <div key={i} className="">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked
                                id={lang + i}
                              />
                              <label
                                htmlFor={lang + i}
                                className="form-check-label px-1 "
                              >
                                {
                                  renderData?.languages?.find(
                                    (lng) => lng.name === lang
                                  )?.[window.lang]
                                }
                                {lang?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {business?.moreInfo?.serviceTypes?.length > 0 && (
                    <div className="accordion-item2">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingTwo"
                      >
                        <button
                          className="accordion-button accordion-button--bus-moreinfo collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          چگونگی ارايه خدمات
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body  ">
                          {business?.moreInfo?.serviceTypes?.map((ST, i) => (
                            <div key={i}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked
                                id={ST + i}
                              />
                              <label
                                className="form-check-label px-1 "
                                htmlFor={ST + i}
                              >
                                {
                                  renderData?.serviceTypes?.find(
                                    (RST) => RST.name === ST
                                  )?.[window.lang]
                                }
                                {ST?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {business?.moreInfo?.paymentMethods?.length > 0 && (
                    <div className="accordion-item2">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingThree"
                      >
                        <button
                          className="accordion-button accordion-button--bus-moreinfo collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          چگونگی پرداخت
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body  ">
                          {business?.moreInfo?.paymentMethods?.map((PM, i) => (
                            <div key={i} className="  ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked
                                id={PM + i}
                              />
                              <label
                                className="form-check-label px-1 "
                                htmlFor={PM + i}
                              >
                                {
                                  renderData?.paymentMethods?.find(
                                    (rePM) => rePM.name === PM
                                  )?.[window.lang]
                                }
                                {PM?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {business?.moreInfo?.workingDays?.length > 0 &&
              business?.moreInfo?.workingDays[0]?.weekDay?.length > 0 && (
                <div className="listing-sidebar-section mt-0 mb-5">
                  <div className="divider2 divider-small">
                    <span>
                      <img
                        src="/img/bussiness-page/alarm-clock.png"
                        className="arrow2 pe-2 py-1"
                        alt="working Days"
                        loading="lazy"
                      />
                    </span>
                    <span className="p-0 mb-0 ">ساعت کاری</span>

                    {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
                  </div>

                  <ul className="list-group">
                    {/* {tools.renderTrans({section:"weekDays",key:da.name})} */}
                    {renderData?.weekDays?.map((da, i) =>
                      business?.moreInfo?.workingDays?.find(
                        (day2) => day2.weekDay == da.name
                      ) ? (
                        <li key={i} className="d-flex justify-content-between">
                          <span>{da?.[window.lang]}</span>
                          <span className="opening">
                            {business?.moreInfo?.workingDays?.map(
                              (day) =>
                                day.weekDay == da.name && (
                                  <div dir="">
                                    <span>از {convertTime(day.from)} </span>
                                    <span>تا {convertTime(day.to)} </span>
                                  </div>
                                )
                            )}
                          </span>
                        </li>
                      ) : (
                        <li className="d-flex justify-content-between">
                          <span>{da?.[window.lang]}</span>
                          {business?.moreInfo?.workingDays.length > 1 && (
                            <span className="closed">بسته</span>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/qr-code.png"
                    className="arrow2 pe-2 py-1"
                    alt="Qr codes"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">
                  {" "}
                  QR-code کسب‌و‌کار در متامارس{" "}
                </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div className="text-center">
                <QRCode
                  value={`https://www.metamarce.com/${
                    window.lang
                  }/business/page/${tools.slugMaker({
                    title: business?.busName[window.lang],
                    city: business?.moreInfo?.address?.city,
                    state: business?.moreInfo?.address?.state,
                    serial: business?.serial,
                  })}`}
                  fgColor="#BAA78D"
                />
              </div>
            </div>
            {business?.moreInfo?.tags?.length > 0 && (
              <div className="listing-details-information p-5  pt-3 my-5 ">
                <div className=" divider-small border-0 mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="tags"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">برچسب‌ها </span>

                  {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
                </div>

                <ul className=" d-flex flex-wrap justify-content-between">
                  {business?.moreInfo?.tags?.map((tag, i) => (
                    <li key={i}>
                      <Link
                        onClick={(e) =>
                          window.scrollTo({ top: 0, smooth: true })
                        }
                        to={`/${window.lang}/business/filters?tag=${tag}`}
                      >
                        <span key={i}>
                          {/* <img
                    src="/img/bussiness-page/tag.png"
                    className="arrow2 pe-2"
                    alt="tags"
                  /> */}
                          {/* <i className="bi bi-hash"></i> */}
                          <i className="bi bi-tag me-1"></i>
                        </span>
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {/* future section!!!!!!! */}
            {/* <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <svg
                  className="text-dark-550 dark:text-white ml-2"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                  <circle
                    cx="35"
                    cy="16"
                    r="8"
                    fill="currentColor"
                    fillOpacity="0.4"
                  ></circle>
                  <circle
                    cx="20"
                    cy="8"
                    r="5"
                    fill="currentColor"
                    fillOpacity="0.7"
                  ></circle>
           
                </svg>
                <span className="p-0 mb-0 ">تنظیم قرار ملاقات</span>

                
              </div>

              <div className="calendar">
                <iframe src="https://calendar.google.com/calendar/embed?src=danagirloftheisland%40gmail.com&ctz=America%2FToronto"></iframe>
              </div>
            </div> */}
          </div>

          {/* <!-- </div> -->*/}
        </div>

        {/* <!-- ممکنه براتون جالب باشه... -->*/}
        {busSlider?.length >= 4 && (
          <div>
            <div className="divider2 ">
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  -->*/}
              </svg>

              <span className="p-0 mb-0 ">ممکنه براتون جالب باشه...</span>
              {/* <img
                src="/img/Images/arrow-09-svgrepo-com.svg"
                className="arrow"
                alt="arrow"
              /> */}
            </div>
            <div className="mx-4">
              <BusCardSlider data={busSlider} />
            </div>

            {/* <BusCardSlider2 
            businessess={data?.businessess}
            subCat={business?.category?.subCategory}
            /> */}
          </div>
        )}
      </div>

      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={business?._id}
            value={business}
            setData={setBusiness}
            collection="Business"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  async function sendEmailCode(e) {
    e.preventDefault();
    if (!id)
      return messageRef.current.showWarning({ message: "کسب و کار پیدا نشد!" });
    // if(business?.moreInfo?.status === "02-claimed" || business?.moreInfo?.status === "03-verified")return messageRef.current.showWarning({message:"این کسب و کار فعال می باشد."})
    document.querySelector("#getPhoneCodeInput").style.display = "none";
    e.target.setAttribute("disabled", true);
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 2 * 2);

    setLoading((pre) => ({ ...pre, loading1: true }));
    const response = await apiBusiness.sendEmailCode({ token: user.token, id });
    setLoading((pre) => ({ ...pre, loading1: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    messageRef.current.showSuccess({
      message: "ایمیل فعالسازی فرستاده شد.",
    });
    document.querySelector("#getEmailCodeInput").style.display = "block";
    document.querySelector(".hideShowControl").style.display = "none";
  }
  async function checkEmailCodeAndShowPhoneInput(e) {
    e.preventDefault();
    console.log(id);
    console.log(emailCode);
    if (emailCode.length < 6)
      return messageRef.current.showError({ message: "کد نامعتبر می باشد." });
    setLoading((pre) => ({ ...pre, loading2: true }));
    const response = await apiBusiness.checkEmailCodeAndShowPhoneInput({
      value: emailCode.trim(),
      token: user.token,
      id,
    });
    setLoading((pre) => ({ ...pre, loading2: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({ message: "کد نامعتبر می باشد." });
    messageRef.current.showSuccess({
      message:
        "ایمیل تائید شد،  تلفن خود را در کادر زیر جهت دریافت کد وارد کنید .",
    });
    e.target.setAttribute("disabled", true);
    // document.querySelector("#getEmailCodeInput").style.display = "none";
    document.querySelector("#getPhoneNumberInput").style.display = "block";

    //  var myModal = document.getElementById('claimModal'); // Get the modal element
    // var modal = window.bootstrap.Modal.getInstance(myModal); // Get the Bootstrap modal instance
    // modal.hide(); // Close the modal
  }

  async function sendCodeToContactPhone(e) {
    e.preventDefault();
    const phone = business.moreInfo?.suggestMode
      ? (contactPhone?.length >= 8 &&
          isValidPhoneNumber(contactPhone) &&
          contactPhone) ||
        business.moreInfo?.businessPhone
      : (contactPhone?.length >= 8 &&
          isValidPhoneNumber(contactPhone) &&
          contactPhone) ||
        (business.moreInfo?.contactPhone?.length &&
          business.moreInfo?.contactPhone);

    e.target.setAttribute("disabled", true);
    setTimeout(() => {
      e.target.removeAttribute("disabled");
    }, 1000 * 60 * 2);
    setLoading((pre) => ({ ...pre, loading4: true }));
    const response = await apiBusiness.sendCodeToContactPhone({
      token: user.token,
      id,
      contactPhone: tools.prepareForSMS(phone),
    });
    setLoading((pre) => ({ ...pre, loading4: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> خطا در انجام عملیات</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "کد فعالسال سازی با پیامک فرستاده شد.",
    });
    document.querySelector("#getEmailCodeInput").style.display = "none";
    document.querySelector("#getPhoneNumberInput").style.display = "none";
    document.querySelector("#getPhoneCodeInput").style.display = "block";
  }

  async function preActivationHandle(e) {
    e.preventDefault();
    console.log(id);
    console.log(codeA);
    setPaswordCounter(passwordCounter + 1);
    console.log(passwordCounter);
    setTimeout(() => setPaswordCounter(1), 1000 * 60 * 15);
    if (passwordCounter > 5)
      return messageRef.current.showError({
        message:
          "تلاش های نادرست شما بیش از 5 می باشد،  پس از 15 دقیقه برای دریافت کد جدید اقدام کنید",
      });

    if (!codeA || codeA.length < 6)
      return messageRef.current.showError({
        message: <div> کد را به شکل صحیح وارد کنید ! </div>,
      });
    setLoading((pre) => ({ ...pre, loading2: true }));
    const response = await apiBusiness.preActivation({
      value: codeA.trim(),
      token: user.token,
      id,
    });
    setLoading((pre) => ({ ...pre, loading2: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> خطا در انجام عملیات!</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message:
        " شماره تلفن با موفقیت تائید شد،  اطلاعات مورد نیاز کسب و کار را تکمیل کنید.",
    });
    e.target.setAttribute("disabled", true);
    var myModal = document.getElementById("claimModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal); // Get the Bootstrap modal instance

    setTimeout(() => {
      modal.hide(); // Close the modal
      navigate(
        `/${window.lang}/dashboard/user/business/edit/${tools.slugMaker({
          title: business.busName[window.lang],
          city: business?.moreInfo?.address?.city,
          state: business?.moreInfo?.address?.state,
          serial: business?.serial,
        })}`,
        {
          state: response.data,
        },
        { replace: true }
      );
    }, 4000);
  }

  function convertTime(time24h) {
    var timeParts = time24h.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Determine AM/PM
    var period = hours < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    }

    // Ensure two-digit formatting
    var hoursStr = hours.toString().padStart(2, "0");
    var minutesStr = minutes.toString().padStart(2, "0");

    // Concatenate the modified values
    var time12h = period + " " + hoursStr + ":" + minutesStr;

    return time12h;
  }
  async function sendTextHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    const hostUser =
      business?.moreInfo?.history?.claimedBy[0]?._id ||
      business?.moreInfo?.history?.createdBy?._id;

    setLoading((pre) => ({ ...pre, spinner: true }));
    // e.target.setAttribute("disabled", true);
    const email = request.email.length ? request.email : user.email;
    if (!hostUser)
      return messageRef.current.showError({
        message: "شناسه کاربری کسب و کار فراهم نشده است",
      });
    const response = await api.sendText({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        hostEmail: business.email,
        hostUser,
        hostId: business._id,
        hostName: business?.busName?.[window.lang],
        lang: window.lang,
        lcs: window.lcs,
        guestId: user._id,
        url: `https://metamarce.com/${window.lang}/business/page/${business._id}`,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>
            درخواست شما با موفقیت برای {business?.busName?.[window.lang]}{" "}
            فرستاده شد و در اولین فرصت در متاچت پاسخ خواهند داد.
          </div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}
function generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink?.match(
    /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
  );
  if (videoIdMatch) {
    const videoId = videoIdMatch[1];
    const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="560"
        height="315"
        src={iframeSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen="allowFullScreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>
    );
  }

  return null; // Return null if video ID couldn't be extracted
}

async function deleteHandle (bus,user,messageRef,setLoading){
  console.log("deleteHandle", bus._id);
  
  if (
    window.confirm(
      `شما در حال حذف دائمی این کسب و کار هستین : "${
        bus.busName[window.lang]
      }"`
    ) !== true
  )
  
    ;
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiBusiness.deleteBus({ token: user.token, id: bus._id });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({ message: response.error });
  messageRef.current.showSuccess({ message: response.message });
  window.navigateHandle(`/${window.lang}/business/filters`)
  
};
