import { useState, useRef, useEffect, useContext } from "react";
import {

  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiJobs";

import Pagination from "../../../golbal/pagination/pagination";
import Cluster from "../../../googleMap/googleCluster";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
import JobCard from "../components/jobCard";
import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import MetaDecorator from "../../../../utils/metaDecorator";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
// import countryStates from "../../../../utils/countryStates";

export default function JobsFilters() {

  const navigate = useNavigate();
  const page = "jobsFilters";
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  // const countryInfo = countryStates.find((count) => count.countryShort.toLowerCase() === countryShort);
  // const stateInfo = countryInfo?.states.find((state) => state?.stateShort.toLowerCase() === stateShort);
  
  
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  const section = "jobs";
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState({
    thumbnail: true,
    cluster: false,
  });

  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ fetchBus: false });
  const [showCities, setShowCities] = useState(false);
  const [data, setData] = useState([]);

  const initialFilters = {
    lang,
    country,
    state,
    stateShort,
    cities: [],
    mainCat: "",
    mainCatId: "",
    subCat: [],
    subCatId: [],
    busId: "",
    busName: "",
    searchKey: "",
    tag: "",
    process: [],
    emType: [],
    workShifts: [],
    validation: [],
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
  };
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(tools.filtersInitiate(initialFilters,storedFilters,state));

  const [backFilters, setBackFilters] = useState({ ...filters });
  // console.log('backFilters',backFilters);

  const effectRan = useRef(true);

  useEffect(() => {
    if (effectRan.current) return;
    filtersFunction()
  }, [filters, window.lcs]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    if(Object.keys(params).length>0){
    setFilters((pre) => ({
      ...pre,
      lang,
      country,
      state,
      stateShort,
      mainCat: params?.mainCat ?? pre.mainCat,
      mainCatId: params?.mainCatId ?? pre.mainCatId,
      subCat: params?.subCat ? [params?.subCat] : pre.subCat,
      subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
      cities: params?.cities ? [params?.cities] : pre.cities,
      searchKey: params?.searchKey ?? pre.searchKey,
      tag: params?.tag ?? pre.tag,
      validation: params?.validation ? [params?.validation] : pre.validation,
      process: params?.process ? [params?.process] : pre.process,
      emType: params?.emType ? [params?.emType] : pre.emType,
      workShifts: params?.workShifts ? [params?.workShifts] : pre.workShifts,
      busId: params?.busId ? params?.busId : pre.busId,
      busName: params?.busName ? params?.busName : pre.busName,
      searchKey: pre.searchKey,
      tag: params?.tag ? params?.tag : pre.tag,
      process: pre.process,
      emType: pre.emType,
      workShifts: pre.workShifts,
    }));}
    filtersFunction()
    effectRan.current = false;
  }, [searchParams]);
  function filtersFunction(){
    console.log('useEffectRun01','filterFunction');    
    fetchJobs();
    setBackFilters({ ...filters });
    storeFilters({ section, filters });
  }

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    // searchParams.set(field,"")
    const searchString = searchParams?.toString();
    setFilters((pre) => {
      const newFilters = { ...pre };
      delete newFilters[field];
      return newFilters;
    });
    navigate(`?${searchString}`);
  }

  async function fetchJobs() {
    setLoading((pre) => ({
      ...pre,
      fetchBus: true,
    }));
    const response = await api.filterJobs({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchBus: false,
    }));
    if (response.error) return alert(response.message);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  const itemsToShowHandle = async (itemsToS) => {
    setFilters((prev) => ({
      ...prev,
      itemsToShow: itemsToS,
      pageNumber: 1,
    }));
  };
  const pageNumberHandle = (PageN) => {
    setFilters((prev) => ({
      ...prev,
      pageNumber: PageN,
    }));
  };

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              if (key === "busName") {
                newFilters.busId = "";
                queryRemover("busId");
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  function selectedFiltersRender() {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (key !== "cities" && key !== "subCat" && key !== "subCatId") {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            // key === "languages"
            //   ? renderData[key]?.find((option1) => option1.name === option)
            //   : renderData[key]?.find((option1) => option1.name === option);
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                {/* <span>{option} </span>        */}
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "busId" &&
        key !== "country" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  }

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `متامارس | کاریابی`;
  const structuredData=[
    // ...renderData.structuredData,
    {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "کاریابی ",
    "url":window.location.href,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,          
          "item": {
            "@id": "https://www.metamarce.com/",
            "name": "شروع",
            "description": "متامارس، پلی بین پارسی‌زبانان سراسر دنیا"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,          
          "item": {
            "@id": `https://www.metamarce.com/${window.lang}`,
            "name": `خانه` ,
            "description": "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !"
          }
        },
        {
          "@type": "ListItem",
          "position": 3,          
          item: {
            "@id": `https://www.metamarce.com/${window.lang}/mp`,
            name: ` نیازمندیها`,
            description: `نیازمندیهای ایرانیان در سراسر دنیا`,
          },
        },
        {
          "@type": "ListItem",
          "position": 4,          
          "item": {
            "@id": `https://www.metamarce.com/${window.lang}/jobs/filters`,
            "name":`لیست مشاغل`,
            "description": `فیلترهای پیشرفته و پر سرعت جهت نمایش دقیق سریع فرصتهای شغلی`
          }
        }
      ]       
    },
    // "about": {
    //   "@type": "Place",
    //   "name": state?.toUpperCase(),
    //   "address": {
    //     "@type": "PostalAddress",
    //     // "addressLocality": "City",
    //     "addressRegion": state?.toUpperCase(),
    //     // "postalCode": "Postal Code",
    //     "addressCountry": country?.toUpperCase()
    //   },
    //   "geo": {
    //     "@type": "GeoCoordinates",
    //     "latitude": lat,  // Replace with the actual latitude
    //     "longitude": lng  // Replace with the actual longitude
    //   }
    // }
  }]
  const metaTagsData={
    title,
    description:`آگهی استخدام رایگان`,
    section:"فیلتر کاریابی",
    url:`https://www.metamarce.com/${window.lang}/jobs/filters`,
    img:"https://www.metamarce.com/img/illustration/man-with-join-us-sign-for-open-recruitment.svg",
    type:'website',
  }
  return (
    <div>
        <MetaDecorator data={{...metaTagsData,structuredData}}/>
 
      {loading.fetchBus && view.thumbnail && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

   
       
          {/* <nav  className="px-2" style={{ bsBreadcrumbDivider: ">", ariaLabel: "breadcrumb" }}>
            <ol className="breadcrumb">
              <Link to={`/${window.lang}`} className="breadcrumb-item">
                خانه
              </Link>
              <Link to={`/${window.lang}/jobs`} className="breadcrumb-item">
                کاریابی
              </Link>
              <div className="breadcrumb-item active">فیلترها</div>
            </ol>
          </nav>
         */}
        <section className="mx-0 py-0">
          {/* <!-- **************Right side ************** --> */}
          <div className="row blog">
          <div className="col-lg-9">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- **************Filters  ************** --> */}
            
            {view.thumbnail && <div>
            <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
                  <div className="d-flex">
                    <span className="pt-2" title="نمایش ذخیره ها">
                      <i
                        className={`bi ${
                          filters?.saved ? "bi-bookmark-fill" : "bi-bookmark"
                        } icon-stack-1x cursor`}
                        onClick={(e) => {
                          if (!window.logined(9999)) return;
                          if (user?.saved?.[section]?.length <= 0)
                            return alert(
                              "شما تاکنون آیتم ذخیره شده ای ندارین."
                            );
                          setFilters((pre) => ({
                            ...initialFilters,
                            saved: !pre.saved,
                            section,
                          }));
                        }}
                      ></i>
                    </span>
                    {resultsTotal !== 0 && (
                      <span className="box4 py-1 px-2 mx-md-2 mx-0">
                        <span className="fw-bold">{tools.dynamicNumber({number:resultsTotal,lang:window.lang})}</span> یافته
                      </span>
                    )}
                    <div className="blog-search ">
                      <BlogSearch searchHandle={searchHandle} />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div
                      className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                      onClick={(e) => {
                        if (!window.logined(9999, "jobs")) {
                          return window.navigateHandle(
                            `/${window.lang}/jobs/register`
                          );
                        }
                        window.navigateHandle(
                          `/${window.lang}/dashboard/user/jobs/register`
                        );
                      }}
                    >
                      <i className="bi bi-geo me-1"></i>
                      افزودن آگهی
                       {/* استخدام */}
                    </div>
                    <button
                      className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo02"
                      aria-controls="navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="bi bi-filter me-1"> فیلترها</i>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse "
                    id="navbarTogglerDemo02"
                  >
                    <div className="d-flex flex-wrap mt-3">
                      {/* <!-- Button filter 1 *******************************--> */}
                      <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-primary button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      // id="#filter5"
                      aria-expanded="false"
                      // aria-controls="filter5"
                    >
                      <span>
                        <i className="bi bi-arrow-down-up me-1"></i>
                      </span>
                      مرتب کن
                      <span className="ps-2">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form px-0 ">
                  <div className=" ">
                    <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                      <input
                        className="form-check-input "
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={filters?.sort === "default"}
                        onChange={(e) => {
                          setFilters((prev) => ({
                            ...prev,
                            sort: "default",
                            pageNumber: 1,
                          }));
                          // effectRan.current = false;
                        }}
                      />
                      <label
                        className="form-check-label "
                        htmlFor="flexRadioDefault1"
                      >
                        پیش فرض
                      </label>
                    </div>

                    <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                      <input
                        className="form-check-input "
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={filters?.sort === "dateB"}
                        onChange={(e) => {
                          // effectRan.current = false;
                          setFilters((prev) => ({
                            ...prev,
                            sort: "dateB",
                            pageNumber: 1,
                          }));
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        تازه‌ترین
                      </label>
                    </div>
                    <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        // value={flexRadioDefault3}
                        checked={filters?.sort === "nameB"}
                        onChange={(e) => {
                          // effectRan.current = false;
                          setFilters((prev) => ({
                            ...prev,
                            sort: "nameB",
                            pageNumber: 1,
                          }));
                        }}
                      />
                      <span className="checkmark-radio-btn "></span>
                      <label
                        className="form-check-label "
                        htmlFor="flexRadioDefault3"
                      >
                        نام کارفرما
                      </label>
                    </div>
                    <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault4"
                        checked={filters?.sort === "viewsB"}
                        onChange={(e) => {
                          // effectRan.current = false;
                          setFilters((prev) => ({
                            ...prev,
                            sort: "viewsB",
                            pageNumber: 1,
                          }));
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault4"
                      >
                        بازدید
                      </label>
                    </div>
                  </div>
                </div>
                  </div>

                   
                      {/* <!-- Button State 2.5 *******************************--> */}
               

                      {/* <!-- Button City 3 *******************************--> */}
                      <div className="dropdown">
                <button
                  type="button"
                  className="btn button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"
                  // id="#filter5"
                  aria-expanded="false"
                  // aria-controls="filter5"
                  onClick={(e) => setShowCities(true)}
                >
                  <span>
                    <i className="bi bi-geo-alt me-1"></i>
                  </span>
                  شهر/ محله
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu checkbox-form">
                  {showCities && (
                    <SearchMultiSelect
                      updateParent={setCitiesHandle}
                      data={filters}
                    />
                  )}
                </div>
              </div>

                      {/* <!-- Button MainCat 3 *******************************--> */}
                      <FiltersCatsSelector
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                  />

                      {/* <!-- Button filter 5 *******************************--> */}
                      <div className="dropdown">

<button
  className="btn btn-primary button-filter"
  type="button"
  data-bs-toggle="offcanvas"
  data-bs-target="#offcanvasWithBothOptionsFilters"
  aria-controls="offcanvasWithBothOptionsFilters"
>
  <span>
    <i className="bi bi-filter me-1"></i>
  </span>
  فیلتر بیشتر
  <span className="ps-2">
    <i className="bi bi-chevron-down"></i>
  </span>
</button>

<div
                className="offcanvas offcanvas-end offcanvas-countryLanguage "
                data-bs-scroll="true"
                tabIndex="-1"
                id="offcanvasWithBothOptionsFilters"
                aria-labelledby="offcanvasWithBothOptionsLabelFilters"
              >
                <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                  <h5
                    className="offcanvas-title "
                    id="offcanvasWithBothOptionsLabelFilters"
                  >
                    فیلترهای بیشتر
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="offcanvas-footer  text-center px-5 py-4">
                  <button
                    className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={(e) => {
                      setFilters(initialFilters)
                      // removing all url queries
                      navigate('')
                    }}
                  >
                    پاک کردن فیلترها
                  </button>
                  <button
                    className="btn  view-cart text-center w-100 py-2"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={(e) => {
                      // effectRan.current = false;
                      backFilters.pageNumber = 1;
                      setFilters(backFilters);
                    }}
                  >
                    نمایش موارد
                  </button>
                </div>

                <div className="offcanvas-body px-0 pb-3 pt-0">
                  <div
                    className="accordion w-100 p-0 m-0"
                    id="accordionPanelsStayOpenBusinessFilters"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseZero"
                        >
                          <p className="form-titr2">شیف های کاری </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseZero"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <div className="accordion-body form-font px-5  row row-cols-2">
                          {renderData.workShifts.map((ws, i) => (
                            <div key={i} className="form-check m-0 py-0 px-1">
                              <input
                                className="form-check-input form-check-input-filter1 m-0 p-0"
                                type="checkbox"
                                // role="switch"
                                name="workShifts"
                                value={ws.name}
                                id={`workShifts,${i}`}
                                checked={backFilters?.workShifts?.includes(
                                  ws.name
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const workShifts = backFilters.workShifts
                                    ? [...backFilters.workShifts]
                                    : [];
                                  const index = workShifts.indexOf(value);
                                  if (index !== -1) {
                                    workShifts.splice(index, 1);
                                  } else {
                                    workShifts.push(ws.name);
                                  }
                                  setBackFilters((prev) => ({
                                    ...prev,
                                    workShifts,
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label px-2"
                                htmlFor={`workShifts,${i}`}
                              >
                                {ws?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingOne"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseOne"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseOne"
                        >
                          <p className="form-titr2">
                            {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                            زبان
                          </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingOne"
                      >
                        <div className="accordion-body form-font px-5  row row-cols-3 ">
                          {renderData.languages.map((lang, i) =>
                            !lang.fa.length ? (
                              ""
                            ) : (
                              <div key={i} className="morelang-line">
                                <input
                                  className="form-check-input  red moreLang"
                                  type="checkbox"
                                  value={lang.name}
                                  checked={backFilters?.languages?.includes(
                                    lang.name
                                  )}
                                  id={`flexLanguages${i}`}
                                  onChange={(e) => {
                                    const languages = backFilters?.languages
                                      ? [...backFilters.languages]
                                      : [];
                                    const value = e.target.value;
                                    const index = languages.indexOf(value);
                                    if (index !== -1) {
                                      languages.splice(index, 1);
                                    } else {
                                      languages.push(value);
                                    }
                                    setBackFilters((pre) => ({
                                      ...pre,
                                      languages,
                                    }));
                                  }}
                                />
                                <label
                                  className="form-check-label ps-2 red moreLang"
                                  htmlFor={`flexLanguages${i}`}
                                >
                                  {lang[window.lang]}
                                </label>
                              </div>
                            )
                          )}

                          <button
                            className="btn button-filter py-0 mt-3"
                            type="button"
                          >
                            <label htmlFor="moreLangCheckbox"> بیشتر... </label>
                            {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                            <input
                              id="moreLangCheckbox"
                              type="checkbox"
                              onClick={(e) => {
                                const elemet =
                                  document.querySelectorAll(".moreLang");
                                if (e.target.checked) {
                                  document.querySelector(
                                    "label[for=moreLangCheckbox]"
                                  ).innerHTML = "کمتر...";
                                  Array.from(elemet).map(
                                    (el) => (el.style.display = "inline-block")
                                  );
                                } else {
                                  document.querySelector(
                                    "label[for=moreLangCheckbox]"
                                  ).innerHTML = "بیشتر...";
                                  Array.from(elemet).map(
                                    (el) => (el.style.display = "none")
                                  );
                                }
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingTwo"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                        >
                          <p className="form-titr2">
                            {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                            نوع همکاری
                          </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                      >
                        <div className="accordion-body form-font px-5">
                          {renderData.emType.map((em, i) => (
                            <div key={i} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={em.name}
                                checked={backFilters?.emType?.includes(em.name)}
                                id={`checkBoxEmType${i}`}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const emType = backFilters.emType
                                    ? [...backFilters.emType]
                                    : []; // Create a shallow copy of the array
                                  console.log("emType", emType);
                                  const index =
                                    backFilters.emType.indexOf(value);
                                  if (index !== -1) {
                                    emType.splice(index, 1);
                                  } else {
                                    // Add the value if it's not selected
                                    emType.push(value);
                                  }
                                  // backFilters.emType=emType
                                  setBackFilters((pre) => ({ ...pre, emType }));
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`checkBoxEmType${i}`}
                              >
                                {em[lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingThree"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseThree"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseThree"
                        >
                          <p className="form-titr2"> پروسه استخدام</p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingThree"
                      >
                        <div className="accordion-body form-font px-5">
                          {renderData.process.map((pro, i) => (
                            <div
                              key={i}
                              className="form-check form-switch m-0 py-0 px-1"
                            >
                              <input
                                className="form-check-input form-check-input-filter1 m-0 p-0"
                                type="checkbox"
                                role="switch"
                                name="process"
                                value={pro.name}
                                id={`process,${i}`}
                                checked={backFilters?.process?.includes(
                                  pro.name
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const process = backFilters.process
                                    ? [...backFilters.process]
                                    : [];
                                  const index = process.indexOf(value);
                                  if (index !== -1) {
                                    process.splice(index, 1);
                                  } else {
                                    process.push(pro.name);
                                  }
                                  setBackFilters((prev) => ({
                                    ...prev,
                                    process,
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label px-2"
                                htmlFor={`process,${i}`}
                              >
                                {pro?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingFour"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseFour"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseFour"
                        >
                          <p className="form-titr2 ps-2">
                            <span>
                              {/* <i className="bi bi-circle-fill "></i> */}
                            </span>
                            سابقه کار
                          </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingFour"
                      >
                        <div className="accordion-body form-font px-5 mb-5">
                          <div className="row row-cols-2   justify-content-evenly g-4">
                            {renderData.jobExperience.map((exper, i) => (
                              <div key={i} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={exper.name}
                                  checked={backFilters.jobExperience?.includes(
                                    exper.name
                                  )}
                                  id={`checkExper${i}`}
                                  onChange={(e) => {
                                    const jobExperience =
                                      backFilters?.jobExperience
                                        ? [...backFilters.jobExperience]
                                        : [];
                                    const value = e.target.value;
                                    const index = jobExperience.indexOf(value);
                                    if (index !== -1) {
                                      jobExperience.splice(index, 1);
                                    } else {
                                      jobExperience.push(value);
                                    }
                                    setBackFilters((pre) => ({
                                      ...pre,
                                      jobExperience,
                                    }));
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`checkExper${i}`}
                                >
                                  {exper?.[window.lang]}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-heading-Five"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapse-Five"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapse-Five"
                        >
                          <p className="form-titr2">اعتبارسنجی </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapse-Five"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-heading-Five"
                      >
                        <div className="accordion-body form-font px-5">
                          {renderData.validation.map(
                            (valid, i) =>
                              valid.name !== "claimed" &&
                              valid.name !== "draft" && (
                                <div
                                  key={i}
                                  className="form-check form-switch m-0 py-0 px-1"
                                >
                                  <input
                                    className="form-check-input form-check-input-filter1 m-0 p-0"
                                    type="checkbox"
                                    role="switch"
                                    id={`validation${i}`}
                                    checked={backFilters?.validation?.includes(
                                      valid.name
                                    )}
                                    value={valid.name}
                                    onChange={(e) => {
                                      const validation = backFilters?.validation
                                        ? [...backFilters.validation]
                                        : [];
                                      const value = e.target.value;
                                      const index = validation.indexOf(value);
                                      if (index !== -1) {
                                        validation.splice(index, 1);
                                      } else {
                                        validation.push(value);
                                      }
                                      setBackFilters((pre) => ({
                                        ...pre,
                                        validation,
                                      }));
                                    }}
                                  />
                                  <label
                                    className="form-check-label px-2"
                                    htmlFor={`validation${i}`}
                                  >
                                    {valid?.[window.lang]}
                                  </label>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ***************** */}
              </div>
            </div>
                    </div>
                  </div>
                  
                </div>
            
                </div>
                }

            <div className=" d-flex flex-wrap my-2 show-filters ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
              >
                <span>{window.capitalize(filters?.country)} ,</span>
                <span>{window.capitalize(filters?.state)}</span>

                <span className="ms-3 me-2">
                  <i className="bi bi-arrow-repeat"></i>
                </span>
              </div>
              {selectedFiltersRender()}
              {selectedFiltersRender().length > 0 && (
                <div
                  className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                  onClick={(e) => {
                    setFilters(initialFilters)
                    // removing all url queries
                    navigate('')
                  }}
                >
                  <span>پاک کردن فیلترها</span>
                </div>
              )}
            </div>

            {/* <!-- Pagination *******************************--> */}
            <div className="mx-0 p-0 mb-2 font-12">
              <div className="d-flex justify-content-between">
                <div className="">
                  {view.thumbnail && (
                    <Pagination
                      data={{ resultsTotal, itemsToShow: filters?.itemsToShow }}
                      setPageNumber={pageNumberHandle}
                      pageNumber={filters?.pageNumber}
                      setItemsToShow={itemsToShowHandle}
                      position="top"
                    />
                  )}
                </div>
                <div className="d-flex">
                      <span
                        className={`me-1 map-card my-2 px-1 d-flex justify-content-center ${
                          view.cluster && "map-card-active"
                        }`}
                        onClick={(e) =>
                          setView({ thumbnail: false, cluster: true })
                        }
                      >
                        نقشه <i className="bi bi-map mx-1 "></i>
                      </span>
                      <span
                        className={`me-1 map-card my-2 px-1 d-flex justify-content-center ${
                          view.thumbnail && "map-card-active"
                        }`}
                        onClick={(e) =>
                          setView({ thumbnail: true, cluster: false })
                        }
                      >
                        کارت<i className="bi bi-image mx-1"></i>
                      </span>
                    </div>           
              </div>

              {/* <img style={{display:view.thumbnail &&loading.fetchBus?'block':'none' }} src="/img/searching-error.svg" alt="Searching..." /> */}
              {data?.length <= 0&& !loading.fetchBus ? (
                <div className="row m-5 px-md-5 px-0">
                  <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                    داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
                    <div
                      className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                      onClick={(e) =>
                        document.querySelector("#lcsChangeBtn").click()
                      }
                    >
                      دیگر استانها یا کشورها
                    </div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <img src="/img/cart-is-empty.svg" alt="no data found" />
                  </div>
                </div>
              ) : (
                <div className="row row-cols-1 row-cols-md-3 justify-content-evenly g-4">
                  {view.thumbnail &&
                    data?.map((job, i) => (
                      <div key={i}>
                        <JobCard
                          job={job}
                          setFilters={setFilters}
                        />
                      </div>
                    ))}
                </div>
              )}

              {view.cluster && (
                <div className="listing-all-map px-0 mx-2">
                  <Cluster
                    data={data}
                    state={{lat,lng}}
                    filters={filters}
                    section="job"
                  />
      
                </div>
              )}

              <div className="mt-5">
                {view.thumbnail && (
                  <Pagination
                    data={{ resultsTotal, itemsToShow: filters?.itemsToShow }}
                    setItemsToShow={itemsToShowHandle}
                    setPageNumber={pageNumberHandle}
                    pageNumber={filters?.pageNumber}
                    position="down"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3  sidebar-all align-items-center text-center  p-md-4 p-1">         
            {/* <!-- ********left side *************************************************************** --> */}

            <MainSidebar section={section} />
          </div>
          </div>
        </section>
        {/* <!-- *********** end of listing ******* --> */}
    

      <section className="mx-md-5 pt-0 mx-0 p-0">
        {/* <!-- تخفیف‌های ویژه --> */}
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>

        <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page={page} />

        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div>

        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

      <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );

  function NewPosts2x2({ newPosts, user }) {
    // console.log(newPosts);
    const redirectHandle = (data) => {
      window.scrollTo({ top: 0, smooth: true });
      navigate(`/${window.lang}/blog/post/${data._id}`, {
        state: { data, newPosts },
      });
    };

    return (
      <>
        {newPosts?.slice(0, 4)?.map((data, i) => (
          <div className="col ">
            <div
              key={i}
              className="card mb-3"
              onClick={(e) => redirectHandle(data)}
            >
              <div className="row g-0">
                <div className="col-lg-4 ">
                  <div className="image-container ">
                    <img
                      src={data.img}
                      className="img-fluid rounded-start w-100  img-card-h "
                      alt="..."
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className={`col-lg-8 small-card${i + 1}`}>
                  <div className="card-body card-h">
                    <h5 className="card-title">
                      {data.title.substring(0, 200)}
                    </h5>
                    <p className="card-text">
                      {/* {data.title.substring(30,200)} */}
                    </p>
                    <p className="card-text">
                      <small
                        className="text-muted"
                       
                      >
                        تاریخ انتشار :                        
            {tools.dynamicDate({date:data.createdAt,countryShort,lang:window.lang,showYear:true,short:true})}

                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}
