import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiMedia";
import countryStates from "../../../../utils/countryStates";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import DropdownSearch from "../../../golbal/dropdown/search";
import SearchPagination from "../../../persian/common/pagination/searchPagination";
import { CircularProgress } from "@mui/material";
import MediaCard from "../components/mediaCard";
import MainSidebar from "../components/mainSidebar";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import BusCardSliderS1 from "../../../golbal/sliders/busCardSliderS1";
import PlansX4 from "../../../golbal/sliders/plansX4";
import tools from "../../../../utils/tools";
import MetaDecorator from "../../../../utils/metaDecorator";
import { CartContext } from "../../../contexts/cartContext";
import BlogSearch from "../../../persian/common/pagination/searchPagination";

// import Social from "../components/jobCard";

export default function MediaFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();
  const navigate = useNavigate();
  const { lang, state:{state,stateShort,lat,lng}, country, countryShort } =
  tools.getItemFromLocalStorage("initSetup", {});
  const [loading, setLoading] = useState({ fetchdata: false });
  const [states, setStates] = useState([]);
  const [resultsTotal, setResultsTotal] = useState(0);
  const section = "media";
  const page = "mediaFilters";
  const initialFilters = {
    lang: window.lang,
    country: "",
    countryShort: "",
    states: [],
    state: "",
    stateShort: "",
    cities: [],
    mainCat: "",
    mainCatId: "",
    subCat: [],
    subCatId: [],
    socialMedias: [],
    userId: "",
    title: "",
    mediaId: "",
    collabTypes: [],
    tag: "",
    searchKey: "",
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
  };

  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(storedFilters || initialFilters);
  const [data, setData] = useState([]);

  const effectRan = useRef(true);
  useEffect(() => {
    if (effectRan.current) return;
    filtersFunction()
  }, [filters]);
  function storeFilters({ section, filters }) {
    const storedFilters =
    JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    if (Object.keys(params).length > 0) {
      setFilters((pre) => ({
        ...pre,
        mainCat: params?.mainCat ?? pre.mainCat,
        mainCatId: params?.mainCatId ?? pre.mainCatId,
        subCat: params?.subCat ? [params?.subCat] : pre.subCat,
        subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
        cities: params?.cities ? [params?.cities] : pre.cities,
        searchKey: params?.searchKey ?? pre.searchKey,
        tag: params?.tag ?? pre.tag,
        socialMedias: params?.socialMedias
          ? [params?.socialMedias]
          : pre.socialMedias,
        userId: params.userId ?? pre.userId,
        title: params.title ?? pre.title,
        mediaId: params.mediaId ?? pre.mediaId,
        collabTypes: params?.collabTypes
          ? [params?.collabTypes]
          : pre.collabTypes,
      }));
    }
    filtersFunction()
    effectRan.current = false;
  }, [searchParams]);

  function filtersFunction(){
    console.log('useEffectRun01','filterFunction');    
    fetchMedias();
    storeFilters({ section, filters });
  }

  function udateparentData(updatedDoc) {
    // console.log('updatedDoc',updatedDoc);
    const newData = data.map((da) =>
      da._id === updatedDoc._id ? updatedDoc : da
    );
    // console.log('newData',newData);
    setData(newData);
  }

  const searchHandle = async (e) => {
    e.preventDefault();
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: e.target.value,
        pageNumber: 1,
      }));
    }
  };
  const viewMorePosts = async (e) => {
    e.preventDefault();
    setFilters((pre) => ({
      ...pre,
      pageNumber: pre.pageNumber + 1,
    }));
  };

  function queryRemover(field) {
    searchParams?.delete(field);
    const searchString = searchParams?.toString();
    navigate(`${window.location.pathname}?${searchString}`);
  }

  async function fetchMedias() {
    setLoading((pre) => ({
      ...pre,
      fetchdata: true,
    }));

    const response = await api.filterMedias({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchdata: false,
    }));
    if (response.error) return alert(response.error);
    setData(response.data.data);
    setResultsTotal(response.data.resultsTotal);
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `متامدیا | بانک سوشیال مدیا `;
  const structuredData = [
    // ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:
        "متامدیا " ,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media`,
              name: `شبکه های اجتماعی`,
              description:
                "بستری برای معرفی و دسترسی گروه‌های تخصصی، شبکه‌های اجتماعی در هر گوشه از دنیا... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lang}/media/filters`,
              name: `جتسجوی شبکه های اجتماعی`,
              description: `فیلترهای پیشرفته و پر سرعت جهت نمایش دقیق سریع پیچ ها و گروه ها در شبکه های اجتماعی`,
            },
          },
        ],
      },
      // about: {
      //   "@type": "Place",
      //   name: state?.toUpperCase(),
      //   address: {
      //     "@type": "PostalAddress",
      //     // "addressLocality": "City",
      //     addressRegion: state?.toUpperCase(),
      //     // "postalCode": "Postal Code",
      //     addressCountry: country?.toUpperCase(),
      //   },
      //   geo: {
      //     "@type": "GeoCoordinates",
      //     latitude: lat, // Replace with the actual latitude
      //     longitude: lng, // Replace with the actual longitude
      //   },
      // },
    },
  ];
  const metaTagsData = {
    title,
    description:
      "متامدیا جایی است برای اتصال،اگر به دنیال شخص یا موضوع خاصی در سوشیال مدیا میگردی، پاسخ بانک سوشیال مدیا متامارس.",
    section: "فیلترهای متامدیا",
    url: `https://www.metamarce.com/${window.lang}/media/filters`,
    canonical: `https://www.metamarce.com/${window.lang}/media/filters`,

    img: "https://www.metamarce.com/img/illustration/online-learning-concept-2.svg",
    type: "webpage",
  };

  const selectedFiltersRender = (() => {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId"
          ) {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "mediaId" &&
        key !== "userId" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {window.capitalize(filters[key])}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {/* <div className="m-3 ms-5"> */}
        <nav className="mb-0" style={{ bsBreadcrumbDivider: ">", ariaLabel: "breadcrumb" }}>
          <ol className="breadcrumb mb-0">
            <Link to={`/${window.lang}`} className="breadcrumb-item">
              خانه
            </Link>
            <Link to={`/${window.lang}/mp`} className="breadcrumb-item">
              نیازمندیها
            </Link>
            <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong>فیلتر شبکه اجتماعی</strong>
            </span>
          </li>
          </ol>
        </nav>
      {/* </div> */}

      <div className="row blog px-md-4 p-0 pb-1 ">
        <div className="col-lg-8 entries ">
          {loading.fetchdata && <ColoredSpiner />}

          <div className=" d-flex flex-wrap x-3 justify-content-between my-2 border-0 ">
            <div className="d-flex">
              <span className="pt-2" title="نمایش ذخیره ها">
                <i
                  className={`bi ${
                    filters.saved ? "bi-bookmark-fill" : "bi-bookmark"
                  } icon-stack-1x cursor`}
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    if (user?.saved?.[section]?.length <= 0)
                      return alert("شما تاکنون آیتم ذخیره شده ای ندارین.");
                    setFilters((pre) => ({
                      ...initialFilters,
                      saved: !pre.saved,
                      section,
                    }));
                  }}
                ></i>
              </span>
              {resultsTotal !== 0 && (
                <span className="box4 py-1 px-2 mx-md-2 mx-0">
                   <span className="fw-bold">{tools.dynamicNumber({number:resultsTotal,lang:window.lang})}</span> یافته
                </span>
              )}
              <div className="blog-search ">
                <BlogSearch searchHandle={searchHandle} />
              </div>
            </div>
            <div className="d-flex">
              <div
                className="btn btn-primary addbusiness-btn my-2 me-2 pt-2"
                onClick={(e) => {
                  if (!window.logined(9999, "media")) {
                    return window.navigateHandle(
                      `/${window.lang}/media/register`
                    );
                  }
                  window.navigateHandle(
                    `/${window.lang}/dashboard/user/media/register`
                  );
                }}
              >
                <i className="bi bi-geo me-1"></i>
                افزودن شبکه اجتماعی
              </div>
              <button
                className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-filter me-1"> فیلترها</i>
              </button>
            </div>
            {/* collapse */}
            <div className="navbar-collapse collapse " id="navbarTogglerDemo02">
              <div className="d-flex flex-wrap mt-3">
                {/* <!-- Button filter 1 *******************************--> */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    // id="#filter5"
                    aria-expanded="false"
                    // aria-controls="filter5"
                  >
                    <span>
                      <i className="bi bi-arrow-down-up me-1"></i>
                    </span>
                    مرتب کن
                    {/* {filters.sort !== "default" && <CheckBox />} */}
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu checkbox-form px-0 ">
                    <div className=" ">
                      <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={filters.sort === "default"}
                          onChange={(e) => {
                            setFilters((prev) => ({
                              ...prev,
                              sort: "default",
                              pageNumber: 1,
                            }));
                            // effectRan.current = false;
                          }}
                        />
                        <label
                          className="form-check-label "
                          htmlFor="flexRadioDefault1"
                        >
                          پیش فرض
                        </label>
                      </div>
                      <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          checked={filters.sort === "rateB"}
                          onChange={(e) => {
                            // effectRan.current = false;
                            setFilters((prev) => ({
                              ...prev,
                              sort: "rateB",
                              pageNumber: 1,
                            }));
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          محبوبیت
                        </label>
                      </div>
                      <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={filters.sort === "dateB"}
                          onChange={(e) => {
                            // effectRan.current = false;
                            setFilters((prev) => ({
                              ...prev,
                              sort: "dateB",
                              pageNumber: 1,
                            }));
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          تازه‌ترین
                        </label>
                      </div>
                      <div className="form-check px-3 d-flex justify-content-between  flex-row-reverse">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          // value={flexRadioDefault3}
                          checked={filters.sort === "nameB"}
                          onChange={(e) => {
                            // effectRan.current = false;
                            setFilters((prev) => ({
                              ...prev,
                              sort: "nameB",
                              pageNumber: 1,
                            }));
                          }}
                        />
                        <span className="checkmark-radio-btn "></span>
                        <label
                          className="form-check-label "
                          htmlFor="flexRadioDefault3"
                        >
                          نام اینفلوئنسر
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Button social media 1.5 *******************************--> */}

                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    // id="#filter5"
                    aria-expanded="false"
                    // aria-controls="filter5"
                  >
                    <span>
                      <i className="bi bi-chat-text me-1"></i>
                    </span>
                    شبکه اجتماعی
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu px-0 checkbox-form">
                    <div
                      className="reset-btn w-100"
                      onClick={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          socialMedias: [],
                          pageNumber: 1,
                        }));
                      }}
                    >
                      پاک کردن
                    </div>
                    {renderData?.socialMedias?.map((media, i) => (
                      <div
                        key={i}
                        className="form-check d-flex justify-content-between flex-row-reverse px-3"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          //  name="subCategory"
                          value={media.name}
                          onChange={(e) => {
                            const socialMedias = filters?.socialMedias
                              ? [...filters.socialMedias]
                              : [];
                            const value = e.target.value;
                            const index = socialMedias.indexOf(value);
                            if (index !== -1) {
                              socialMedias.splice(index, 1);
                            } else {
                              socialMedias.push(value);
                            }
                            setFilters((pre) => ({ ...pre, socialMedias }));
                          }}
                          checked={filters.socialMedias?.includes(media.name)}
                          id={`flexCheckSM${i}`}
                        />

                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckSM${i}`}
                        >
                          {media?.[window.lang]}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <!-- Button country 2 *******************************--> */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    کشور
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu checkbox-form">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div>

                {/* <!-- Button State 2.5 *******************************--> */}
                {filters.country?.length > 0 && (
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="bi bi-geo-alt me-1"></i>
                      </span>
                      استان
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu px-0 checkbox-form">
                      <div
                        className="reset-btn w-100"
                        onClick={(e) => {
                          setFilters((prev) => ({
                            ...prev,
                            states: [],
                            state: "",
                            stateShort: "",
                            pageNumber: 1,
                          }));
                        }}
                      >
                        پاک کردن
                      </div>
                      {states?.map((state, i) => (
                        <div
                          key={"states" + i}
                          className="form-check d-flex justify-content-between flex-row-reverse px-3"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // value={state.name?.toLowerCase()}
                            onChange={(e) => {
                              const states = filters?.states
                                ? [...filters.states]
                                : [];
                              const state1 = { stateShort: "", state: "" };
                              const value = state.state;
                              console.log("value", value);
                              const index = states.indexOf(value);
                              if (index !== -1) {
                                states.splice(index, 1);
                                const contry = countryStates.find(
                                  (count) =>
                                    count.countryShort ===
                                    filters.countryShort.toUpperCase()
                                );
                                const stateShort = contry.states.find(
                                  (state) => state.state === states[0]
                                ).stateShort;
                                console.log("stateShort", stateShort);
                                // const stateShort= countryStates.find(count=>count.countryShort === filters.countryShort)
                                if (states.length === 1) {
                                  state1.stateShort = stateShort;
                                  state1.state = states[0];
                                } else {
                                  state1.stateShort = "";
                                  state1.state = "";
                                }
                              } else {
                                states.push(value);
                                if (states.length === 1) {
                                  state1.stateShort = state.stateShort;
                                  state1.state = value;
                                } else {
                                  state1.stateShort = "";
                                  state1.state = "";
                                }
                              }
                              setFilters((pre) => ({
                                ...pre,
                                states,
                                state: state1.state,
                                stateShort: state1.stateShort,
                              }));
                            }}
                            checked={filters.states?.includes(state.state)}
                            id={`statesCheckBox${i}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`statesCheckBox${i}`}
                          >
                            {state?.state}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* <!-- Button City 3 *******************************--> */}
                {filters.states.length === 1 && filters?.state?.length > 0 && (
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn button-filter dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="bi bi-geo-alt me-1"></i>
                      </span>
                      شهر/ محله
                      <span className="ps-3">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </button>

                    {/* <!-- Modal --> */}

                    <div className="dropdown-menu checkbox-form">
                      <SearchMultiSelect
                        updateParent={setCitiesHandle}
                        data={{
                          countryShort: filters.countryShort,
                          stateShort: filters.stateShort,
                          cities: filters.cities,
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* <!-- Button MainCat 3 *******************************--> */}
                <FiltersCatsSelector
                  section={section}
                  filters={filters}
                  setFilters={setFilters}
                />

                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    // id="#filter5"
                    aria-expanded="false"
                    // aria-controls="filter5"
                  >
                    <span>
                      <i className="bi bi-briefcase me-1"></i>
                    </span>
                    همکاری
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div
                    className="dropdown-menu px-0 checkbox-form"
                    style={{ width: "250px" }}
                  >
                    {renderData?.collabTypes?.map((collab, i) => (
                      <div
                        key={i}
                        className="form-check d-flex justify-content-between flex-row-reverse px-3"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"collab-" + i}
                          checked={filters.collabTypes?.includes(collab.name)}
                          onClick={(e) => {
                            const collabTypes = [...filters.collabTypes] ?? [];
                            const index = collabTypes.indexOf(collab.name);
                            if (index !== -1) {
                              collabTypes.splice(index, 1);
                            } else {
                              collabTypes.push(collab.name);
                            }
                            setFilters((pre) => ({ ...pre, collabTypes }));
                          }}
                        />
                        <label className="form-check-label" for={"collab-" + i}>
                          {collab[window.lang]}
                        </label>
                      </div>
                    ))}

                    {/* <div className="form-check d-flex justify-content-between flex-row-reverse px-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="colab-med"
                    />
                    <label className="form-check-label" for="colab-med">
                 تبادل با دیگر صفحه‌ها
                    </label>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" d-flex flex-wrap mt-1 mb-3 show-filters">
            {selectedFiltersRender}
            {selectedFiltersRender.length > 0 && (
              <div
                className=" filter-del-btn px-3 shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
                onClick={(e) => {
                  // removing all url queries
                  navigate('')
                  setFilters(initialFilters)
                }}
              >
                <span>پاک کردن فیلترها</span>
              </div>
            )}
          </div>

          {loading.fetchdata ? (
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <img
                src="/img/searching-error.svg"
                alt="Searching..."
                loading="lazy"
              />
            </div>
          ) : (
            resultsTotal <= 0 && (
              <div className="row m-5 px-md-5 px-0">
                <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                  داده ای پیدا نشد، با فیلتر های دیگر امتحان کنید !
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                  <img src="/img/cart-is-empty.svg" alt="cart-is-empty" />
                </div>
              </div>
            )
          )}
          <div className="row"></div>
          <div className="row row-cols-1 row-cols-md-2  mx-auto justify-content-evenly g-3">
            {data?.map((post, i) => {
              return (
                <MediaCard
                  key={i}
                  data={post}
                  udateparentData={udateparentData}
                />
              );
            })}
          </div>
          <div className="mt-2 d-flex justify-content-center pe-5">
            <button
              className="btn px-5 btn-sm shadow-none login-btn p-1 "
              onClick={viewMorePosts}
              disabled={resultsTotal <= data?.length || loading.fetchdata}
            >
              {loading.fetchdata ? (
                <CircularProgress color="inherit" size="20px" />
              ) : resultsTotal <= data?.length ? (
                "پایان یافته ها"
              ) : (
                "موارد بیشتر"
              )}
            </button>
          </div>
        </div>
        <div className="col-lg-4  sidebar-all align-items-center text-center  p-md-4 p-1">
          <MainSidebar section={section} />
        </div>
      </div>
      <section className="mx-md-5 pt-0 mx-0">
        {/* <!-- تخفیف‌های ویژه --> */}
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>

        <BusCardSliderS1 plansS1={pubData?.ads?.plansS1} page="mediaFilters" />

        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
          {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> --> */}
        </div>

        <PlansX4 plansX4={pubData?.ads?.plansX4} page={page} />
      </section>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    setFilters(update());
  }

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
  }

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    if (field === "mainCat") queryRemover("mainCatId");
    if (field === "subCat") queryRemover("subCatId");

    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat" || key === "mainCatId") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
              }
              if (key === "country") {
                newFilters.states = [];
                newFilters.cities = [];
              }
              if (key === "title") {
                newFilters.userId = "";
                newFilters.mediaId = "";
                queryRemover("userId");
                queryRemover("mediaId");
              }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    const updatedFilters = update();

    setFilters(updatedFilters);
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    // console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    const state = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states[0];
    setStates(states);

    setFilters((prev) => ({
      ...prev,
      country: regionNames.of(countryShort).toLowerCase(),
      countryShort: countryShort.toLowerCase(),
      state: "",
      stateShort: "",
      states: [],
      cities: [],
    }));
  }
}
